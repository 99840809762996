@import '../../views/styles/colors.scss';

.LoadingButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    opacity: 1;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .LoadingSpinner {
        margin-left: 0.5rem;
    }
}

.dark-grey {background: $darkGrey}
.dark-grey:hover {background: $hoverGrey}