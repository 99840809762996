@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.base {
    width: 100%;
    overflow: hidden;
    background: #edebe9 linear-gradient(to bottom, #dbd7d1, #edebe9 116px) no-repeat;

    .base-children {
        position: relative;
        min-height: calc(100vh - 5.6rem);
        margin-top: 5.6rem;
        margin-left: 12%;
        margin-right: 20%;
        background: $black;
        z-index: 2;

        @include lg {
            position: sticky;
            left: 0;
            width: 100vw;
            min-height: calc(100vh - 3.75rem);
            margin: 3.75rem 0 0;
            transition: margin-left 0.5s ease-in-out;
        }

        &.is-match-page {
            @include md {
                margin-top: 0;
            }
            @include sm {
                margin-top: 3.75rem;
            }
        }


        .main-banner {
            position: fixed;
            display: block;
            width: 100%;
            height: 12.5rem;
        }

        .main-wrapper {
            position: relative;
            padding: 0 1.55rem;

            @include lg {
                padding: 0;
            }
        }
    }
}