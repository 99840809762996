@import '../../views/styles/colors.scss';

.LoadingSpinner {
    position: relative;
    width: 1rem;
    height: 1rem;

    div {
        display: block;
        position: absolute;
        width: 0.8rem;
        height: 0.8rem;
        margin: 0.1rem;
        border: 0.15rem solid #FFF;
        border-radius: 50%;
        animation: spinning 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;        
        }

        &:nth-child(3) {
            animation-delay: -0.15s;        
        }
    }
}
  
@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}