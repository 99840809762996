// Screen Sizes
$xxs: 320px;
$xs: 360px;
$sm: 576px;
$md: 767px;
$lg: 1024px;
$l: 1200px;
$xl: 1400px;
$xxl: 1600px;
$fhd: 1920px;
$qhd: 2200px;
$qhdp: 2560px;

// Mixins
@mixin xxs {
    @media (max-width: #{$xxs}) {
        @content;
    }
}

@mixin xs {
    @media (max-width: #{$xs}) {
        @content;
    }
}

@mixin sm {
    @media (max-width: #{$sm}) {
        @content;
    }
}

@mixin md {
    @media (max-width: #{$md}) {
        @content;
    }
}

@mixin lg {
    @media (max-width: #{$lg}) {
        @content;
    }
}

@mixin l {
    @media (max-width: #{$l}) {
        @content;
    }
}

@mixin xl {
    @media (max-width: #{$xl}) {
        @content;
    }
}

@mixin xxl {
    @media (max-width: #{$xxl}) {
        @content;
    }
}

@mixin fhd {
    @media (max-width: #{$fhd}) {
        @content;
    }
}

@mixin qhd {
    @media (max-width: #{$qhd}) {
        @content;
    }
}

@mixin qhdp {
    @media (max-width: #{$qhdp}) {
        @content;
    }
}