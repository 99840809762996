@import './colors.scss';
@import './fonts.scss';
@import './mixins.scss';

html, body {margin: 0; height: 100%; overflow: hidden}

.por {position: relative}

.darker {background: #bbb}

.rt-smallrow {
    padding: 0px 0px !important;
    line-height: 22px
}

.rt-smallrow div {
    padding: 0px 5px !important;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

.ta-left {
    text-align: left
}

.ta-center {
    text-align: center
}

* {
    font-weight: 700;
    font-family: 'Source Sans Pro' !important
}

i {
    font-weight: 900;
    font-family: 'Font Awesome 5 Free' !important
}

i.fab {
    font-family: 'Font Awesome 5 Brands' !important
}

input {
    font-weight: 400;
    font-family: 'Source Sans Pro' !important
}

html {
    background: #edebe9;
    font-size: 20px;

    @include qhd {
        font-size: 18px;
    }

    @include fhd {
        font-size: 16px;
    }

    @include xxl {
        font-size: 14px;
    }

    @include xl {
        font-size: 12px;
    }

    @include l {
        font-size: 10px;
    }

    @include lg {
        font-size: 16px;
    }

    @include xs {
        font-size: 15px;
    }

    @include xxs {
        font-size: 14px;
    }
}

body {
    margin: 0;
    padding: 0;
    background: $black;
    // font-family: $Roboto;

    &::-webkit-scrollbar {
        width: 5px !important;
    }
    
    &::-webkit-scrollbar-track {
        background: $lightBlack;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $grey;
    }
    
    * {
        box-sizing: border-box;
    }
}

button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    // font-family: $Roboto;
    cursor: pointer;
}

input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    // font-family: $Roboto;
}

a {
    text-decoration: none;
}