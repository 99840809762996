@import './colors.scss';

.button {
    min-width: 6rem;
    height: 2rem;
    line-height: calc(2rem - 4px);
    padding: 0 1rem;
    border: 2px solid transparent;
    color: $white;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;

    &.black-orange {
        border-color: $orange;
    }

    &.black-green {
        border-color: $green;
    }

    &.orange {
        background: $orange;
    }

    &.green {
        background: $green;
    }
}